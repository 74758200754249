@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Noto Sans KR";
  src: url("../font/NotoSansKR-Regular.otf"), url("../font/NotoSansKR-Bold.otf");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url("../font/NotoSansKR-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Regular.ttf"), url("../font/Roboto-Bold.ttf");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

* {
  font-family: "Noto Sans KR", "Roboto", sans-serif !important;
  box-sizing: border-box;
}

.ace_content,
.ace_editor div {
  font: 12px / normal "Monaco", "Menlo", "Ubuntu Mono", "Consolas",
    "source-code-pro", monospace !important;

  span {
    font-family: inherit !important;
  }
}

.scrollbar {
  overflow-y: auto;
  scrollbar-track-color: #f4f6f7;
  scrollbar-arrow-color: #babac0;
  scrollbar-3dlight-color: #f4f6f7;
  scrollbar-highlight-color: #f4f6f7;
  scrollbar-shadow-color: #babac0;
  scrollbar-face-color: #babac0;

  &::-webkit-scrollbar {
    background: inherit;
    width: 6px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.block-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.input-warning {
  border-color: #5b5e86 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

// * About Flex
.flex-2 {
  flex: 2 !important;
}

.flex-3 {
  flex: 3 !important;
}

.flex-4 {
  flex: 4 !important;
}
